import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/Layout';
import { BasicHedgehogImage } from '../components/BasicHedgehogImage';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {/* AUTO-GENERATED-CONTENT:START (TOC) */}

    {/* AUTO-GENERATED-CONTENT:END */}

    <br />
    <br />
    <br />
    <br />
    <span className='centered'>
      <h2>{`Oops, there's nothing here`}</h2>
      <p>{`Think this a mistake? Email `}<em parentName="p"><a parentName="em" {...{
            "href": "mailto:hey@aiprentice.com"
          }}>{`hey@aiprentice.com`}</a></em>{` and we'll fix it!`}</p>
      {/* AUTO-GENERATED-CONTENT:START (TOC) */}
      {/* AUTO-GENERATED-CONTENT:END */}
      <p><a parentName="p" {...{
          "href": "/"
        }}>{`Take me back to the homepage`}</a></p>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      